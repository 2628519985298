import React from "react";

import {
  BrowserRouter,
  Switch,
  Route,

} from "react-router-dom";


import Homepage from "./components/homepage.js";



class App extends React.Component {

  render() {

    return (
      <BrowserRouter>
        <Switch>
          <Route path="/" render={props => <Homepage key={props.match.params} {...props} />}/>

        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
