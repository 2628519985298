import React, {Fragment}from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import queryString from 'query-string'

import "../css/styles.css";
import LogoChevrolet from "./chevrolet-logo.png";

class Homepage extends React.Component {

  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      integrado: true,
      isLoading: true,
    };
  }



componentDidMount(){

  this._isMounted = true;



        const queryHeader = {headers: {
          "Access-Control-Allow-Origin" : "*",
          "Content-type": "Application/json",
          }   
        }

        const searchString = queryString.parse(this.props.location.search)
        const code = searchString.code
        const dealer_id = searchString.state

        console.log('code: ', code)
        console.log('dealer_id: ', dealer_id)

        //HOMOLOG
        // const query = `https://homolog.pecab2c.ccm.eng.br/api/v1/dealers/integrate/mercadopago?dealerId=${dealer_id}&code=${code}`

        //PROD
        const query = `https://pecab2c.ccm.eng.br/api/v1/dealers/integrate/mercadopago?dealerId=${dealer_id}&code=${code}`


        axios.post( query, queryHeader )
        .then((response) => {
          if (this._isMounted) {

            this.setState({ integrado: true, isLoading: false });

          }
        })    
        // não deu 201 no endpoint shipping
        .catch(err => {
        console.log(err)
        this.setState({ integrado: false, isLoading: false });
        });


  

}
componentWillUnmount() {
  this._isMounted = false;
}







  render() {

    // console.log(this.props.match.params)

    return (
        <div>
              <div className="header-bkgd">
                <div className="header-wrap">
                  <div className="header-logo-pc">

                      <img className="logo-pc" src={LogoChevrolet} alt="logo Chevrolet" />

                    <div className="vl"></div>

                    <div>
                      <header>
                        <ul className="titulo-pc">
                          <li>PEÇA</li>
                          <li className="subtitulo-pc">CHEVROLET</li>
                        </ul>
                      </header>
                    </div>

                    <div className="vl"></div>

                    <div>
                      <header>
                        <ul className="admin-header-subtitle">
                          <li className="header-item">Vinculação da conta Mercado-Pago com o Peça Chevrolet</li>
                        </ul>
                      </header>
                    </div>

                  </div>
                </div>
              </div>


              {this.state.isLoading ? (

                  <div className="loader-homepage-wrap">
                      <img className="loader-homepage-img" src={require('../img/loader.gif').default} alt="Loading..." />
                      <div className="loader-homepage-text"> 
                          Carregando
                      </div>
                  </div>

              ):( 
                  <Fragment>

                      {this.state.integrado ? (

                        <div className="master-wrap">
                            <div className="master-text">
                                Estamos finalizando o processo para vincular sua conta do Mercado Pago com o Peça Chevrolet.
                            </div>
                            <div className="master-text">
                                Você pode fechar esta página e voltar para o Admin e verificar o andamento na aba CONFIGURAÇÃO.
                            </div>
                        </div>

                      ):(

                        <div className="master-wrap">
                            <div className="master-text">
                                Ocorreu um erro de comunicação com o Mercado Pago.
                            </div>
                            <div className="master-text">
                                Você deve fechar esta página e tentar novamente mais tarde.
                            </div>
                        </div>

                      )}
                  </Fragment>


              )}
        </div>

    );
  }
}

export default withRouter(Homepage);
